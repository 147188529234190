
.input-container {
  position: relative;
  width: 300px; /* Adjust width as needed */
}

.input-container input {
  width: 100%;
  padding-right: 40px; /* Adjust padding to make space for the button */
  box-sizing: border-box;
}

.input-container button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 20px 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

